var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "company-landing-page" } }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "div",
          [
            _c("stageline-back-button", {
              attrs: { text: "Back to Select Company" },
            }),
            _c("h2", { staticClass: "title" }, [
              _vm._v("\n        Managing:\n        "),
              _c("span", { staticClass: "company-name" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.currentCompany?.name || "") +
                    "\n        "
                ),
              ]),
            ]),
          ],
          1
        ),
        _vm.loaded
          ? [
              _c("task-list"),
              _c("stageline-overview", { staticClass: "stageline-overview" }),
              _c("company-overview", { staticClass: "company-overview" }),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }